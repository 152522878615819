import { request } from './request' // 引入封装好的axios对象
import store from '../store'

// 手机号登录
export function login (account, password) {
  return request({
    url: '/api/login/login',
    method: 'post',
    params: {
      account,
      password,
      sdclkid: sessionStorage.getItem('sdclkid') || '',
      bd_vid: sessionStorage.getItem('bd_vid') || ''
    }
  })
}

// 手机验证码登录
export function mobilelogin (mobile, captcha, prPhone) {
  return request({
    url: '/api/login/mobilelogin',
    method: 'post',
    params: {
      mobile,
      captcha,
      prPhone,
      sdclkid: sessionStorage.getItem('sdclkid') || '',
      bd_vid: sessionStorage.getItem('bd_vid') || ''
    }
  })
}
// 获取图片验证码
export function getImgCode (mobile, event) {
  return request({
    url: '/api/login/getImages',
    method: 'post',
    params: {
      mobile,
      event
    }
  })
}
// 获取手机注册验证码
export function getCode (mobile, event, imgMsg) {
  return request({
    url: '/api/login/sendMsg2',
    method: 'post',
    params: {
      mobile,
      event,
      imgMsg
    }
  })
}

// 验证图片验证码
export function captchaCode (imgCaptcha) {
  return request({
    url: '/api/login/getImages',
    method: 'post',
    params: {
      captcha: imgCaptcha
    }
  })
}

// 注册
export function register (mobile, password, code, key, invitedCode, prPhone) {
  return request({
    url: '/api/login/register',
    method: 'post',
    params: {
      mobile,
      password,
      code,
      key,
      invitedCode,
      prPhone,
      sdclkid: sessionStorage.getItem('sdclkid') || '',
      bd_vid: sessionStorage.getItem('bd_vid') || ''
    }
  })
}
// 是否会员
export function userMember (phone) {
  return request({
    url: '/api/user/isUserName',
    method: 'post',
    params: {
      phone
    }
  })
}

// 重置密码
export function chongzhi (mobile, newpassword, captcha) {
  return request({
    url: '/api/login/resetpwd',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      mobile,
      newpassword,
      captcha
    }
  })
}

// 更换头像
export function getPicer (avater) {
  return request({
    url: '/api/balance/profile',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      avater
    }
  })
}

// 首页商品列表
export function getIndexGoods (params) {
  return request({
    url: '/api/index/get_goods_info',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 首页访问日志
export function seachLog (params) {
  return request({
    url: '/api/goods/seach_log',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage (params) {
  return request({
    url: '/api/goods/get_all_storage',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage2 (params) {
  return request({
    url: '/api/goods/get_all_storage2',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage4 (params) {
  return request({
    url: '/api/goods/get_all_storage4',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage3 (params) {
  return request({
    url: '/api/goods/get_all_storage3',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 所有云仓信息/api/goods/get_all_cloud
export function getAllCloud (params) {
  return request({
    url: '/api/goods/get_all_cloud',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 所有云仓信息/api/goods/get_all_cloud
export function getAllCloud2 (params) {
  return request({
    url: '/api/goods/get_all_cloud2',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 根据平台筛选仓库
export function choosePlatform (platform) {
  return request({
    url: '/api/goods/choose_platform',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: platform
  })
}
export function getYcAuthServiceUrl (params) {
  return request({
    url: '/api/autodeliver/getYcAuthServiceUrl',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 所有商品
export function getAllGoods (params) {
  return request({
    url: '/api/goods/get_all_goods',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 商品筛选
export function goodsSearchGs (params) {
  return request({
    url: '/api/goods/search_gs',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 淘宝店铺绑定
export function storeBind () {
  return request({
    url: '/api/tbbandstore/permit',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取店铺绑定信息
export function getBandStore (params) {
  return request({
    url: '/api/bandstore/getUserShopList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 删除店铺绑定
export function getBandDelete (params) {
  return request({
    url: '/api/tbbandstore/storeDelete',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 获取店铺名称
export function getStoreName (data) {
  return request({
    url: '/api/tbbandstore/getBandStore',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 根据淘宝店铺名称获取订单列表
export function getTaoBaoOrderList (data) {
  return request({
    url: '/api/tbbandstore/getOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 搜索淘宝订单列表
export function searchTaoBaoOrderList (data) {
  return request({
    url: '/api/tbbandstore/getNewOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function getpddOrderList (data) {
  return request({
    url: '/api/bandstore/getOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 根据拼多多店铺名称获取订单列表
export function getDyOrderList (data) {
  return request({
    url: '/api/autodeliver/getOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据京东店铺名称获取订单列表
export function getYcOrderListApi (data) {
  return request({
    url: '/api/autodeliver/getYcOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function getNewDyOrderList (data) {
  return request({
    url: '/api/autodeliver/getNewOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据京东店铺名称获取订单列表
export function getYcNewOrderListApi (data) {
  return request({
    url: '/api/autodeliver/getYcNewOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function searchpddOrderList (data) {
  return request({
    url: '/api/bandstore/getNewOrderList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 改变店铺自动发货状态
export function autoChange (data) {
  return request({
    url: '/api/tbbandstore/dev_button',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 创建订单和包裹
export function createOrder (data) {
  return request({
    url: '/api/order/create_order',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 异常订单列表
export function errorOrderListApi (data) {
  return request({
    url: '/api/order/refundPackageList',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 异常订单处理
export function dealWithErrorOrder (data) {
  return request({
    url: '/api/order/updateRefundPackage',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 计算应付金额
export function orderEndPayPrice (data) {
  return request({
    url: '/api/order/calculation_order_price',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}
// 发件地址添加
export function addArea (data) {
  return request({
    url: '/api/area/area_add',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    data
  })
}

// 发件地址查询
export function getAreaList () {
  return request({
    url: '/api/area/area_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取订单列表
export function getOrderList (params) {
  return request({
    url: '/api/order/get_order_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 首页广告位
export function getAdsInfo (params) {
  return request({
    url: '/api/index/get_ads_info',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 首页配置信息
export function getSiteInfo (params) {
  return request({
    url: '/api/index/get_site_info',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 配置验证码信息
export function getSitepassword (event) {
  return request({
    url: '/api/index/get_site_info',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      event
    }
  })
}
// 包裹筛选
export function getPackageList (params) {
  return request({
    url: '/api/order/get_package_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 获取全部系统公告
export function getNoticeAll (params) {
  return request({
    url: '/api/notice/noticeAll',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 系统公告分类筛选
export function getNoticeSelect (params) {
  return request({
    url: '/api/notice/noticeSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 工单写入
export function matterWrite (params) {
  return request({
    url: '/api/matter/matterWrite',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 工单上传图片
export function imgsHome (params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/matter/imgsHome',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 工单筛选
export function matterSelect (params) {
  return request({
    url: '/api/matter/matterSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 改变工单状态
export function matterStatus (params) {
  return request({
    url: '/api/matter/matterStatus',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 用户基本信息
export function getUserMoney () {
  return request({
    url: '/api/login/userInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 低金额是否需要实名认证
export function getCheckIdCard (money) {
  return request({
    url: '/api/user/getCheckMoney',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      money
    }
  })
}

// 重新推送
export function pushThirdOrder (id) {
  return request({
    url: '/api/order/pushThirdOrder',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      id
    }
  })
}

// 余额支付
export function orderPay (id, key) {
  return request({
    url: '/api/order/order_pay',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      id,
      key
    }
  })
}

// 改变订单状态
export function changeOrderStatus (params) {
  return request({
    url: '/api/order/change_order_status',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 发件地址删除
export function areaDel (id) {
  return request({
    url: '/api/area/area_del',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      area_id: id
    }
  })
}

// 默认发件地址选择
export function homeArea (id) {
  return request({
    url: '/api/area/homeArea',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      area_id: id
    }
  })
}

// 修改密码
export function resetpwd (password) {
  return request({
    url: '/api/balance/resetpwd',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      password: password
    }
  })
}

// 修改支付宝账号
export function resetzfb (zfb, realname) {
  return request({
    url: '/api/balance/resetZfb',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      zfb: zfb,
      real_name: realname
    }
  })
}

// 邀请的奖励信息筛选
export function rewardSelect (params) {
  return request({
    url: '/api/invite/rewardSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 符合状态的子订单数量
export function statusNum (params) {
  return request({
    url: '/api/order/status_num',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 充值
export function topuprice (amount, payType) {
  return request({
    url: '/api/api/pay',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''

    },
    params: {
      amount,
      payType
    }
  })
}
// 充值
export function officialRecharge (money, paytype) {
  return request({
    url: '/api/api/paySubmit',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''

    },
    params: {
      money,
      paytype
    }
  })
}
// 工单记录
export function resolve (params) {
  return request({
    url: '/api/matter/matterDetail',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 余额信息
export function moneyInfo () {
  return request({
    url: '/api/balance/moneyInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请人ID
export function inviteHttp () {
  return request({
    url: '/api/invite/inviteHttp',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请的用户信息筛选
export function inviteSelect (params) {
  return request({
    url: '/api/invite/inviteSelect',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 邀请的全部用户信息
export function selectAll () {
  return request({
    url: '/api/invite/selectAll',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 修改手机号
export function changemobile (mobile, captcha) {
  return request({
    url: '/api/login/changemobile',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      mobile,
      captcha
    }
  })
}

// 金钱变动列表
export function moneylist (params) {
  return request({
    url: '/api/balance/moneylist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 佣金变动列表
export function profitlist (params) {
  return request({
    url: '/api/balance/profitlist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 引导用户登录授权
export function shouquan () {
  return request({
    url: '/api/agiso/shouquan',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 提现
export function balanceApply (params) {
  return request({
    url: '/api/balance/apply',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 表格导入
export function importExcel (params) {
  const fd = new FormData()
  fd.append('file', params.file)
  fd.append('platform', params.platform)
  return request({
    url: '/api/excel/import_excel',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 快递停发区域
export function stopArea (id) {
  return request({
    url: '/api/area/stoparea',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: {
      deliver_id: id
    }
  })
}

// 常见工单问题
export function matterResolve () {
  return request({
    url: '/api/matter/matterResolve',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    method: 'post'
  })
}

// 我的会员信息
export function myMemberInfo () {
  return request({
    url: '/api/user/usergroup',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取所有会员等级
export function allMemberLv (params) {
  return request({
    url: '/api/user/usergrouplist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 快递获取所有会员等级
export function fastmailMemberLv (params) {
  return request({
    url: '/api/user/usergroupalllist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 获取用户绑定店铺
export function getUserBindShop () {
  return request({
    url: '/api/bandstore/getUserShop',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 提交一键发货
export function updateSendGoodsApi (params) {
  return request({
    url: '/api/autodeliver/autoDeliver',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 提交一键发货
export function dyBindShop (params) {
  return request({
    url: '/api/autodeliver/bindShop',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 实名认证 /api/user/trueName
export function idCardApi (params) {
  return request({
    url: '/api/user/trueName',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

export function idCardPhoneApi (params) {
  return request({
    url: '/api/user/trueNameAndPhone',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
export function idCardUnPhoneApi (params) {
  return request({
    url: '/api/user/trueNameAndPhone',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 是否保存网址
export function isSaveDomainEnd () {
  return request({
    url: '/api/user/saveUrl',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 是否保存网址
export function isSaveDomain () {
  return request({
    url: '/api/user/isSaveUrl',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 包裹导出
export function exportPackage (params) {
  return request({
    url: '/api/order/excel_package',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// api/autodeliver/bindYcShop
export function getYcAuthApi (params) {
  return request({
    url: '/api/autodeliver/getYcAuth',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// api/autodeliver/bindYcShop
export function bindYcShopApi (params) {
  return request({
    url: '/api/autodeliver/bindYcShop',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

export function moneyDetailExport (params) {
  return request({
    url: '/api/balance/excelMoney',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 设置默认快递
export function setDefaultStorage (params) {
  return request({
    url: '/api/user/setDefaultStorage',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 获取设置的默认快递
export function getDefaultStorage () {
  return request({
    url: 'api/user/getDefaultStorage',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

export function getSelectwuliu (params) {
  return request({
    url: 'api/order/selectwuliu',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 复制地址
export function getAddString (params) {
  return request({
    url: 'api/order/copy_package_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 淘宝店铺绑定新
export function newTbStoreBind () {
  return request({
    url: '/api/tbbandstore/getTbShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}
// 淘宝店铺绑定新
export function newTbBindSuccess () {
  return request({
    url: '/api/tbbandstore/tbShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}
// 抖音授权
export function newDyStoreBind () {
  return request({
    url: '/api/autodeliver/dyJdfShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}
// 微信充值新
export function paySubmitWeiXinPc (params) {
  return request({
    url: '/api/user/paySubmitWeiXinPc',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 微信充值轮询回调
export function getOrderSuccess (params) {
  return request({
    url: '/api/user/getOrderSuccess',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 推广收益
export function promotionprofitlist () {
  return request({
    url: '/api/balance/promotionprofitlist',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}
// 推广收益提现
export function applyromotionprofit (params) {
  return request({
    url: '/api/balance/applyromotionprofit',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
